<template>
  <b-form @submit.prevent>
    <h4 class="mb-4">Account aanmaken</h4>
    <b-form-group label="Voor en achternaam:" label-for="name">
      <b-input-group>
        <b-form-input
          v-model.trim="signupForm.name"
          type="text"
          placeholder="Timo Boll"
          id="name"
        ></b-form-input>
        <b-input-group-append>
          <b-input-group-text>
            <b-icon icon="person" />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-form-group
      label="NTTB rating:"
      label-for="nttbRating"
      description="Speel je geen competitie? Vul dan niets in"
    >
      <b-input-group>
        <b-form-input
          v-model.trim="signupForm.nttbRating"
          type="number"
          placeholder="bijv 850"
          id="nttbRating"
        ></b-form-input>

        <b-input-group-append>
          <b-input-group-text>
            <b-icon icon="graph-up" />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-form-group
      id="input-group-1"
      label="E-mailadres:"
      label-for="email1"
      description="We delen nooit je email adres met een andere partij"
    >
      <b-input-group append="@">
        <b-form-input
          v-model.trim="signupForm.email"
          type="text"
          placeholder="jeeigenmail@gmail.com"
          id="email1"
        ></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group id="input-group-2" label="Wachtwoord:" label-for="password1">
      <b-input-group>
        <b-form-input
          v-model.trim="signupForm.password"
          type="password"
          placeholder="*******"
          id="password1"
          v-on:keyup.enter="signUp()"
        />
        <b-input-group-append>
          <b-input-group-text>
            <b-icon icon="lock" />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <p v-if="signupErrorCode" class="text-danger">
      <small>{{ translateErrorCode() }}</small>
    </p>

    <b-button variant="primary" class="float-right" @click="signUp()">
      Registreren
    </b-button>
    <p class="mt-2">
      <small>
        Let op, je account wordt nog gekeurd.
      </small>
    </p>
  </b-form>
</template>

<script>
import * as fb from '../firebase'

export default {
  data() {
    return {
      signupForm: {
        name: '',
        nttbRating: 0,
        email: '',
        password: ''
      },
      signupErrorCode: '',
      ratingNewUser: 1
    }
  },
  created() {
    this.getHighestRating()
  },
  methods: {
    countOccurrences(arr, val) {
      return arr.reduce((a, v) => (v === val ? a + 1 : a), 0)
    },

    async getHighestRating() {
      await fb.usersCollection
        .orderBy('ladderRating', 'desc')
        .onSnapshot(snapshot => {
          let ladderRatings = []
          snapshot.forEach(doc => {
            let ladderRating = doc.data().ladderRating
            ladderRatings.push(ladderRating)
          })

          const highestRating = Math.max(...ladderRatings)
          const highestRatingMaxAmount = this.countOccurrences(
            ladderRatings,
            highestRating
          )

          //set the ratingNewUser
          highestRating === highestRatingMaxAmount
            ? (this.ratingNewUser = highestRating + 1)
            : (this.ratingNewUser = highestRating)

          return
        })
    },

    async signUp() {
      try {
        if (this.signupForm.name === '') throw 'form/name-empty'
        // sign user up
        const { user } = await fb.auth.createUserWithEmailAndPassword(
          this.signupForm.email,
          this.signupForm.password
        )

        // create user profile object in userCollections
        await fb.usersCollection.doc(user.uid).set({
          name: this.signupForm.name,
          nttbRating: parseInt(this.signupForm.nttbRating) || 0,
          ladderRating: this.ratingNewUser,
          totalPoints: 0,
          lastGameId: 0,
          id: user.uid,
          creationTime: user.metadata.creationTime,
          approved: false
        })

        // fetch user profile and set in state
        this.$store.dispatch('fetchUserProfile', user)
      } catch (error) {
        console.log(error)
        error === 'form/name-empty'
          ? (this.signupErrorCode = error)
          : (this.signupErrorCode = error.code)
      }
    },
    translateErrorCode() {
      switch (this.signupErrorCode) {
        case 'form/name-empty':
          return 'Geef uw voor en achternaam op'

        case 'auth/invalid-email':
          return 'Het email adres is niet juist'

        case 'auth/email-already-in-use':
          return 'Het email adres is al in gebruik'

        case 'auth/user-disabled':
          return 'Deze gebruiker is gedeactiveerd'

        case 'auth/weak-password':
          return 'Het wachtwoord moet minimaal 6 characters zijn'

        default:
          'Er is iets mis gegaan'
      }
    }
  }
}
</script>

<style lang="scss"></style>
