<template>
  <b-modal
    id="passwordreset"
    title="Reset je wachtwoord"
    header-bg-variant="light"
    header-text-variant="dark"
    body-bg-variant="white"
    body-text-variant="dark"
    footer-bg-variant="light"
    footer-text-variant="dark"
    header-close-variant="dark"
  >
    <template v-if="!showSuccess">
      <p>Voer je email in om je wachtwoord te resetten</p>
      <b-form @submit.prevent>
        <b-form-group id="input-group-1">
          <b-input-group append="@">
            <b-form-input
              v-model.trim="email"
              type="email"
              placeholder="jeeigenmail@gmail.com"
              id="email1"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-form>
      <p v-if="errorMsg" class="text-danger">
        <small>{{ errorMsg }}</small>
      </p>
    </template>
    <p v-else class="m-0">Gelukt! Check je email voor een reset link.</p>

    <template #modal-footer>
      <b-button
        v-if="!showSuccess"
        variant="primary"
        @click="resetPassword()"
        class="float-right"
      >
        Reset wachtwoord
      </b-button>
      <b-button
        v-if="showSuccess"
        @click="$bvModal.hide('passwordreset')"
        variant="primary"
        class="float-right"
      >
        Sluiten
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { auth } from '@/firebase'

export default {
  data() {
    return {
      email: '',
      showSuccess: false,
      errorMsg: ''
    }
  },
  methods: {
    async resetPassword() {
      this.errorMsg = ''

      try {
        await auth.sendPasswordResetEmail(this.email)
        this.showSuccess = true
      } catch (err) {
        this.errorMsg = err.message
      }
    }
  }
}
</script>
