<template>
  <b-form @submit.prevent class="w-100">
    <PasswordResetModal />

    <h4 class="mb-4">Inloggen</h4>
    <b-form-group id="input-group-1" label-for="email1" label="Email">
      <b-input-group append="@">
        <b-form-input
          v-model.trim="loginForm.email"
          type="email"
          placeholder="jeeigenmail@gmail.com"
          id="email1"
        ></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group id="input-group-2" label="Wachtwoord:" label-for="password1">
      <b-input-group>
        <b-form-input
          v-model.trim="loginForm.password"
          type="password"
          placeholder="*******"
          id="password1"
          v-on:keyup.enter="login()"
        />
        <b-input-group-append>
          <b-input-group-text>
            <b-icon icon="lock" />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <p v-if="loginErrorCode" class="text-danger">
      <small>{{ translateErrorCode() }}</small>
    </p>

    <b-button
      variant="text"
      size="sm"
      v-b-modal.passwordreset
      class="text-muted"
    >
      Wachtwoord vergeten
    </b-button>
    <b-button variant="primary" @click="login()" class="float-right">
      Inloggen
    </b-button>
  </b-form>
</template>

<script>
import * as fb from '../firebase'
import PasswordResetModal from '@/components/PasswordResetModal'

export default {
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      loginErrorCode: ''
    }
  },
  methods: {
    async login() {
      try {
        // sign user in
        const { user } = await fb.auth.signInWithEmailAndPassword(
          this.loginForm.email,
          this.loginForm.password
        )
        // fetch user profile and set in state
        this.$store.dispatch('fetchUserProfile', user)
      } catch (error) {
        this.loginErrorCode = error.code
      }
    },
    translateErrorCode() {
      switch (this.loginErrorCode) {
        case 'auth/invalid-email':
          return 'Het email adres is niet juist'

        case 'auth/user-disabled':
          return 'Deze gebruiker is gedeactiveerd'

        case 'auth/user-not-found':
          return 'Deze gebruiker is niet gevonden'

        case 'auth/wrong-password':
          return 'Verkeerd wachtwoord opgegeven'

        default:
          'Er is iets mis gegaan'
      }
    }
  },
  components: {
    PasswordResetModal
  }
}
</script>
