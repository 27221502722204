<template>
  <b-container>
    <b-row no-gutters class="mt-3 justify-content-sm-center">
      <b-col md="8" lg="6" class="shadow bg-white text-dark">
        <div class="py-5 px-4 login-bg">
          <div class="content text-light text-center">
            <b-img
              :src="require(`@/assets/img/logo-ttv-harderwijk-ladder.svg`)"
              alt="Logo TTV Harderwijk"
              width="150"
              rounded
              center
              class="mb-4 shadow"
            />
            <h3>
              Laddercompetitie
            </h3>
            <hr class="border-white w-25" />
            <p>
              Ben je hier voor het eerst en wil je graag mee doen met de ladder
              competitie?
            </p>
            <b-button size="sm" to="/aanmelden" variant="warning">
              Aanmelden
            </b-button>
          </div>
        </div>
        <div class="p-4">
          <LoginForm />
        </div>
      </b-col>
    </b-row>
    <div class="text-dark p-2 mt-2 text-center">
      <small class="text-muted">
        Ben je hier voor het eerst?
        <b-button variant="warning" to="/aanmelden" size="sm" class="mx-1">
          Aanmelden
        </b-button>
      </small>
    </div>
  </b-container>
</template>

<script>
import PasswordResetModal from '@/components/PasswordResetModal'
import LoginForm from '@/components/LoginForm'

export default {
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      }
    }
  },
  components: {
    PasswordResetModal,
    LoginForm
  }
}
</script>
