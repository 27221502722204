<template>
  <b-container>
    <b-row no-gutters class="mt-3 justify-content-sm-center">
      <b-col md="8" lg="6" class="shadow bg-white text-dark">
        <div class="py-5 px-4 account-bg">
          <div class="content text-dark text-center">
            <b-img
              :src="require(`@/assets/img/logo-ttv-harderwijk-ladder.svg`)"
              alt="Logo TTV Harderwijk"
              width="150"
              rounded
              center
              class="mb-4 shadow"
            />
            <h3>Aanmelden laddercompetitie</h3>
            <hr class="border-dark w-25" />
            <p>
              Om mee te doen met de TTV Harderwijk laddercompetitie dien je lid
              te zijn van de vereniging. Maak je account hier aan.
            </p>
            <p>
              Al een account?
            </p>
            <b-button size="sm" to="/login" variant="primary">
              inloggen
            </b-button>
          </div>
        </div>
        <div class="p-4">
          <SignupForm />
        </div>
      </b-col>
    </b-row>
    <div class="text-dark p-2 mt-2 text-center">
      <small class="text-muted">
        Heb je al een account?
        <b-button variant="warning" to="/login" class="mx-1" size="sm">
          Inloggen
        </b-button>
      </small>
    </div>
  </b-container>
</template>

<script>
import SignupForm from '@/components/SignupForm'

export default {
  components: {
    SignupForm
  }
}
</script>
